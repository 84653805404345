import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap'
import axios from "../API/Axios";

function SearchCertificates() {

    let navigate = useNavigate();

    const [code, setCode] = useState("");
    const [show, setShow] = useState(false);

    let handleInputChange = (event) => {
        setCode(event.target.value)
    }

    const isValidCode = (code) => {

        // This regex allows letters, numbers, and the '-' symbol
        const regex = /^[a-zA-Z0-9-ñÑ]*$/;

        // Ensure the code is not just whitespace
        // Ensure the code has at least 2 characters
        // Ensure the only symbol used is '-'

        if (!code.trim() || code.length < 2 || !regex.test(code)) {
            setShow(true)
            return false;
        }
    
        setShow(false)
        return true;
    }

    const sendCode = (event) => {
        event.preventDefault();
        if (isValidCode(code)) {
            if (code.indexOf("-") > -1) { //condition if code has "-" it's a single certificate
                axios.get("Certificate/" + code)
                    .then(response => {
                        navigate("/certificado/" + code);
                    })
                    .catch(error => {
                        navigate("/certificados/error");
                    });
            } else { //get all certificates codes from one student
                navigate("/certificadosEstudiante/" + code);
            }
        }
    }

    return (
        <>
            <section className="container  p-3">
            <Alert show={show} variant="danger" onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>Caracteres invalidos</Alert.Heading>
                    <p>
                        Utilice solo letras, numeros y unicamente el caracter "-" para buscar un alumno o certificado
                    </p>
                </Alert>
                <form onSubmit={sendCode} className="text-center mt-3 mb-3">
                    <h1 className="text-white fs-3 mt-3">Ingrese codigo de certificado</h1>

                    <div className="row mt-5 justify-content-center">
                        <div className="col-7 col-md-3">
                            <input onChange={handleInputChange} name="code" type="text" className="w-100 form-control" placeholder="Codigo de certificado o alumno" />
                        </div>

                        <div className="col-auto ">
                            <button type="submit" className="btn btn-primary">Buscar</button>
                        </div>
                    </div>

                </form>
            </section>
        </>

    )
}
export default SearchCertificates